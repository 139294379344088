
export const portraitImages = [

    {
     id:1,
     src:"../images/img1.JPG",
     title: "beautiful Land",
     price: "2000",
     size: "2000 x 2000",
    },

    {
     id:2,
     src:"../images/img2.JPG",
     title: "beautiful Land",
     price: "2000",
     size: "2000 x 2000",
    },

    {
     id:3,
     src:"../images/img3.JPG",
     title: "beautiful Land",
     price: "2000",
     size: "2000 x 2000",
    },
   
    {
     id:4,
     src:"../images/img4.JPG",
     title: "beautiful Land",
     price: "2000",
     size: "2000 x 2000",
    },

    {
     id:5,
     src:"../images/img5.JPG",
     title: "beautiful Land",
     price: "2000",
     size: "2000 x 2000",
    },

    {
     id:6,
     src:"../images/img6.JPG",
     title: "beautiful Land",
     price: "2000",
     size: "2000 x 2000",
    },
   
    {
     id:7,
     src:"../images/img7.JPG",
     title: "beautiful Land",
     price: "2000",
     size: "2000 x 2000",
    },
   
    {
     id:8,
     src:"../images/img8.JPG",
     title: "beautiful Land",
     price: "2000",
     size: "2000 x 2000",
    },
      
    {
     id:9,
     src:"../images/img9.JPG",
     title: "beautiful Land",
     price: "2000",
     size: "2000 x 2000",
    },
   
    {
     id:10,
     src:"../images/img10.JPG",
     title: "beautiful Land",
     price: "2000",
     size: "2000 x 2000",
    },

    {
     id:11,
     src:"../images/img11.JPG",
     title: "beautiful Land",
     price: "2000",
     size: "2000 x 2000",
   },
];
